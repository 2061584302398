<template>
  <div class="mx-auto">
    <v-skeleton-loader
      background-color="inherit"
      v-if="loadingitem"
      height="150"
      class="mx-auto"
      type="list-item-three-line, image, article"
    ></v-skeleton-loader>
    <v-card v-else rounded="xl" hover v-click-outside="clickOutside">
      <div class="pa-2 mx-auto">
        <router-link
          style="text-decoration: none; color: inherit"
          :to="{ name: 'BuyItemDetails', params: { id: itemid } }"
        >
          <v-row>
            <v-col class="pa-2" cols="12" md="7">
              <p
                :to="{ name: 'BuyItemDetails', params: { id: itemid } }"
                v-if="thisitem.creator != thisitem.transfer.seller"
                class="text-capitalize subtitle-2 ma-2 text-left"
              >
                <v-icon left> mdi-repeat </v-icon>{{ thisitem.title }}
              </p>

              <p v-else class="text-capitalize subtitle-2 ma-2 text-left">
                <v-icon color="primary" left>mdi-check-all </v-icon>
                {{ thisitem.title }}
              </p>

              <span>
                <p
                  class="caption pl-12 font-weight-light"
                  v-if="thisitem.description.length < 120"
                >
                  {{ thisitem.description }}
                </p>
                <p class="caption pl-12 font-weight-light" v-else>
                  {{ thisitem.description.substring(0, 118) + ".." }}
                </p>
              </span>
            </v-col>

            <v-col class="pa-2" cols="12" md="5">
              <div v-if="imageurl">
                <v-img
                  class="rounded-lg contain ma-2 mb-0"
                  @load="loadedphoto = true"
                  :aspect-ratio="4 / 3"
                  :src="imageurl"
                  ><v-skeleton-loader
                    background-color="inherit"
                    v-if="!loadedphoto"
                    type="image"
                    height="120"
                  ></v-skeleton-loader
                ></v-img>
              </div><div v-else-if="getTokenUri() && uri">
                 <v-img
                  class="rounded-lg contain ma-2 mb-0"
                  @load="loadedphoto = true"
                  :aspect-ratio="4 / 3"
                  :src="uri"
                  ><v-skeleton-loader
                    background-color="inherit"
                    v-if="!loadedphoto"
                    type="image"
                    height="120"
                  ></v-skeleton-loader
                ></v-img>
                </div>
            </v-col>
          </v-row>
        </router-link>
      </div>
      <v-card-actions>
        <v-btn
          plain
          class="mr-2"
          icon
          @click="(showinfo = !showinfo), getItemPhotos()"
        >
          <v-icon>{{
            showinfo ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
        <div>
          <!--<v-btn class="rounded-pill ml-6 mr-6"  small
             color="primary"
            :to="{ name: 'BuyItemDetails', params: { id: itemid } }"
            plain
          >
            Details
          </v-btn>-->
        </div>
        <div v-if="thisitem.creator != thisitem.transfer.seller">
          <span
            ><router-link
              style="text-decoration: none; color: inherit"
              :to="{ name: 'BuyItemDetails', params: { id: itemid } }"
            >
              <v-chip
                style="cursor: pointer"
                class="mr-2 pr-0"
                v-if="
                  thisitem.transfer.shipping_cost > 0 &&
                  thisitem.transfer.location == '' &&
                  thisitem.transfer.discount == 0
                "
                small
                dark
                color="primary lighten-1"
              >
                <v-hover v-slot="{ hover }" close-delay="100" open-delay="30">
                  <span>
                    <span class="pr-2" v-if="hover"> Buy Now </span
                    ><span class="pr-2" v-else
                      >{{
                        (Number(thisitem.estimation.estimation_price) +
                          Number(thisitem.transfer.shipping_cost)) /
                        1000000
                      }}<v-icon small right>$vuetify.icons.custom</v-icon>
                    </span>
                  </span>
                </v-hover>

                <v-chip label class="pl-0 caption" color="primary"
                  ><v-icon small left> mdi-repeat </v-icon>
                  <v-icon small left> mdi-plus </v-icon
                  ><v-icon small left> mdi-package-variant-closed </v-icon>
                </v-chip>
              </v-chip></router-link
            >
          </span>

          <span
            ><router-link
              style="text-decoration: none; color: inherit"
              :to="{ name: 'BuyItemDetails', params: { id: itemid } }"
            >
              <v-chip
                v-if="
                  thisitem.transfer.shipping_cost > 0 &&
                  thisitem.transfer.location == '' &&
                  thisitem.transfer.discount > 0
                "
                small
                dark
                color="primary lighten-1"
                style="cursor: pointer"
                class="mr-2 pr-0"
              >
                <v-hover v-slot="{ hover }" close-delay="300" open-delay="60">
                  <span>
                    <span class="pr-2" v-if="hover"> Buy Now </span
                    ><span class="pr-3" v-else
                      >{{
                        (Number(thisitem.estimation.estimation_price) +
                          Number(thisitem.transfer.shipping_cost) -
                          Number(thisitem.transfer.discount)) /
                        1000000
                      }}<v-icon small>$vuetify.icons.custom</v-icon>
                    </span>
                  </span>
                </v-hover>

                <v-chip label class="pl-0 caption" color="primary"
                  ><v-icon small right> mdi-repeat </v-icon
                  ><v-icon small right> mdi-plus </v-icon
                  ><v-icon small right> mdi-package-variant-closed </v-icon>
                  <v-icon small right> mdi-plus </v-icon
                  ><v-icon small right> mdi-brightness-percent</v-icon>
                </v-chip>
              </v-chip>
            </router-link>
          </span>

          <span
            ><router-link
              style="text-decoration: none; color: inherit"
              :to="{ name: 'BuyItemDetails', params: { id: itemid } }"
            >
              <v-chip
                v-if="
                  thisitem.transfer.discount > 0 &&
                  thisitem.transfer.location != ''
                "
                small
                dark
                color="primary lighten-1"
                style="cursor: pointer"
                class="mr-2 pr-0"
              >
                <v-hover v-slot="{ hover }" close-delay="300" open-delay="60">
                  <span>
                    <span class="pr-2" v-if="hover"> Buy Now </span
                    ><span class="pr-3" v-else
                      >{{
                        (thisitem.estimation.estimation_price -
                          thisitem.transfer.discount) /
                        1000000
                      }}<v-icon small right>$vuetify.icons.custom</v-icon></span
                    >
                  </span>
                </v-hover>

                <v-chip label class="pl-0 caption" color="primary "
                  ><v-icon small right> mdi-repeat </v-icon>
                  <v-icon small right> mdi-plus </v-icon
                  ><v-icon small right> mdi-brightness-percent</v-icon>
                </v-chip>
              </v-chip></router-link
            >
          </span>
        </div>
        <div v-else>
          <span v-if="thisitem.transfer.location == ''">
            <router-link
              style="text-decoration: none; color: inherit"
              :to="{ name: 'BuyItemDetails', params: { id: itemid } }"
            >
              <v-chip
                style="cursor: pointer"
                class="mr-2 pr-0"
                small
                dark
                color="primary"
              >
                <v-hover v-slot="{ hover }" close-delay="300" open-delay="60">
                  <span>
                    <span class="pr-2" v-if="hover"> Buy Now </span
                    ><span class="pr-3" v-else
                      >{{
                        (Number(thisitem.estimation.estimation_price) +
                          Number(thisitem.transfer.shipping_cost)) /
                        1000000
                      }}<v-icon small right>$vuetify.icons.custom</v-icon>
                    </span>
                  </span>
                </v-hover>

                <v-chip label class="pl-0 caption" color="primary lighten-1">
                  <v-icon right> mdi-check-all </v-icon
                  ><v-icon small right> mdi-plus </v-icon
                  ><v-icon small right> mdi-package-variant-closed </v-icon>
                </v-chip>
              </v-chip>
            </router-link>
          </span>

          <span v-else>
            <router-link
              style="text-decoration: none; color: inherit"
              :to="{ name: 'BuyItemDetails', params: { id: itemid } }"
            >
              <v-chip
                style="cursor: pointer"
                class="mr-2 pr-0"
                small
                dark
                color="primary lighten-1"
              >
                <v-hover v-slot="{ hover }" close-delay="300" open-delay="60">
                  <span>
                    <span class="pr-2" v-if="hover"> Buy Now </span
                    ><span class="pr-3 caption" v-else
                      >{{ thisitem.estimation.estimation_price / 1000000
                      }}<v-icon small right>$vuetify.icons.custom</v-icon></span
                    >
                  </span>
                </v-hover>

                <v-chip label class="pl-0 caption" color="primary">
                  <v-icon right> mdi-check-all </v-icon>
                </v-chip>
              </v-chip>
            </router-link>
          </span>
        </div>
        <v-chip
          color="primary lighten-2"
          small
          v-if="thisitem.transfer.discount > 0"
          class="mx-2 font-weight-medium"
        >
          {{
            Math.floor(
              (thisitem.transfer.discount /
                thisitem.estimation.estimation_price) *
                100
            )
          }}% <v-icon small right> mdi-brightness-percent </v-icon>
        </v-chip>

        <v-chip
          color="primary"
          small
         v-if="thisitem.properties.condition != 0"
          class="mx-2 font-weight-medium"
        >
          {{ thisitem.properties.condition }}
          <!--<v-rating
                            :value="Number(thisitem.properties.condition)"
                            readonly
                            color="white"
                            background-color="primary lighten-1"
                            x-small
                            dense
                          ></v-rating> --><v-icon small right>mdi-star</v-icon>
        </v-chip>

        <v-spacer></v-spacer>
      </v-card-actions>

      <v-expand-transition>
        <div>
          <div class="pa-2 mx-auto" elevation="8" v-if="showinfo">
            <div>
              <div v-if="photos[0]">
                <v-divider></v-divider>
                <v-carousel
                  cycle
                  height="400"
                  hide-delimiter-background
                  show-arrows-on-hover
                >
                  <v-carousel-item
                    v-for="(photo, i) in photos"
                    :key="i"
                    :src="photo"
                  >
                  </v-carousel-item>
                </v-carousel>
              </div>

              <span>
                <div class="pa-2 overline text-center">Description</div>
                <v-card-text>
                  <div class="caption">{{ thisitem.description }}</div>
                </v-card-text>
              </span>
              <v-chip
                :to="{ name: 'SearchTag', params: { tag: itemtags } }"
                outlined
                medium
                class="ma-1 caption font-weight-light text-capitalize"
                v-for="itemtags in thisitem.properties.tags"
                :key="itemtags"
              >
                <v-icon small left> mdi-tag-outline </v-icon
                >{{ itemtags }}</v-chip
              >
              <v-chip
                :to="{ name: 'SearchRegion', params: { region: selected } }"
                outlined
                class="ma-1 caption font-weight-light"
                v-for="selected in thisitem.transfer.shipping_region"
                :key="selected"
              >
                <v-icon small left> mdi-flag-variant-outline </v-icon
                >{{ selected.toUpperCase() }}</v-chip
              >
              <v-chip
                class="ma-1 caption font-weight-light"
                :to="{ name: 'BuyItemDetails', params: { id: itemid } }"
                outlined
              >
                <v-icon left> mdi-account-badge-outline </v-icon>
                TRST ID: {{ thisitem.id }}
              </v-chip>

              <v-chip class="ma-1 caption font-weight-light" outlined>
                <v-icon small left> mdi-star </v-icon>
                Condition: {{ thisitem.properties.condition }}/5
              </v-chip>

              <v-chip
                v-if="thisitem.transfer.location != ''"
                class="ma-1 caption font-weight-light"
                outlined
                ><v-icon left> mdi-map-marker-outline </v-icon> Location
                available</v-chip
              >

              <v-chip
                v-if="thisitem.transfer.shipping_cost > 0"
                class="ma-1 caption font-weight-light"
                outlined
              >
                <v-icon left> mdi-package-variant-closed </v-icon>
                Shipping: {{ thisitem.transfer.shipping_cost / 1000000 }}
                <v-icon right small>$vuetify.icons.custom</v-icon>
              </v-chip>

              <v-chip
                :to="{ name: 'BuyItemDetails', params: { id: itemid } }"
                v-if="thisitem.estimation.estimation_price > 0"
                class="ma-1 caption font-weight-light"
                outlined
              >
                <v-icon left> mdi-check-all </v-icon>
                Price: {{ thisitem.estimation.estimation_price / 1000000
                }}<v-icon right small>$vuetify.icons.custom</v-icon>
              </v-chip>

              <v-btn
                class="rounded-pill ml-6 mr-6"
                small
                color="primary"
                :to="{ name: 'BuyItemDetails', params: { id: itemid } }"
                plain
              >
                More Details
              </v-btn>
            </div>
          </div>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import { databaseRef } from "./firebase/db";

export default {
  props: ["itemid"],

  data() {
    return {
      //itemid: this.item.id,
      //make sure deposit is number+token before sending tx

      showinfo: false,
      imageurl: "",
      loadingitem: true,
      loadedphoto: false,
      photos: [],
    };
  },

  beforeCreate() {
    this.loadingitem = true;
  },

  mounted() {
    if (this.thisitem.properties.photos[0]){
this.imageurl =this.thisitem.properties.photos[0]

    }else{
    const imageRef = databaseRef.ref(
      "ItemPhotoGallery/" + this.itemid + "/photos/"
    );

    imageRef.on("value", (snapshot) => {
      const data = snapshot.val();
      // console.log(data)
      if (data != null) {
        //console.log(data[0]);
        this.imageurl = data[0];
        this.loadingitem = false;
      }
    });
    }
    this.loadingitem = false;
  },
  computed: {
    thisitem() {
      console.log(this.itemid);
      return this.$store.getters.getItemFromListByID(this.itemid);
    },

    hasAddress() {
      return !!this.$store.state.account.address;
    },
    valid() {
      return this.amount.trim().length > 0;
    },
    commentlist() {
      return this.thisitem.estimation.comments.filter((i) => i != "") || [];
    },
  },

  methods: {
    getItemPhotos() {
      if( this.thisitem.properties.photos[0]){
         this.photos = data;
            this.loadingitem = false;
      }
     else if (this.showinfo && this.imageurl != "") {
        //this.loadingitem = true;
     const imageRef = databaseRef.ref("ItemPhotoGallery/" +  this.itemid + "/photos/");
        imageRef.on("value", (snapshot) => {
          const data = snapshot.val();
          if (data != null) {
            this.photos = data;
            this.loadingitem = false;
          }
        });
        this.loadingitem = false;
      }
    },
    clickOutside() {
      if ((this.showinfo = true)) {
        this.showinfo = false;
      }
    },
      async getTokenUri() {
      if (this.thisitem.properties.token_uri != "") {
        let link = await this.$store.dispatch(
          "getTokenUri",
          this.thisitem.properties.token_uri
        );
        if (link.image.substring(0, 4) == "ipfs") {
          //console.log(link.image.replace("ipfs://", "https://ipfs.io/ipfs/"));
          this.uri = link.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        } else {
          this.uri = link.image;
        }
      }
    },
  },
};
</script>

